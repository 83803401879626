/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
.navbar div.navi {
  float: left;
  display: block;
}
.navbar div.navi > .item {
  float: left;
}
.navbar div.navi > .item > .menu {
  display: block;
}
.navbar div.sub1 {
  position: relative;
  min-width: 100%;
  padding-bottom: 10px;
  display: inline-flex;
  flex-flow: row-reverse nowrap;
  justify-content: flex-start;
}
.navbar div.sub1 > .item {
  flex: 1 0 0px;
}
.navbar div.sub1 > .item > .menu {
  padding: 20px 10px 20px 10px;
  background: #4E5055;
  box-shadow: 0 0 6px #000000;
  color: #fff;
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20px;
}
.navbar div.sub1 > .item > .menu.path {
  background-color: #E30613;
}
@media only screen and (min-width: 1920px) {
  .navbar div.sub1 > .item > .menu {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.navbar .cb-page-selected-layout1 div.sub1 > .item > .menu {
  background-color: #E30613;
}
.navbar div.sub2 {
  box-sizing: border-box;
  float: none;
  position: absolute;
  left: 100%;
  top: calc(100% - 10px);
  width: calc(100vw / 3 - 60px);
  max-width: 0;
  height: 100vh;
  padding-top: 40px;
  background-color: rgba(227, 6, 19, 0.9);
  overflow: hidden;
  transition: max-width 250ms ease;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.navbar div.sub2 > .item {
  float: none;
  padding-left: 30px;
}
.navbar div.sub2 > .item.init {
  display: none;
}
.navbar div.sub2 > .item > .menu {
  box-sizing: border-box;
  width: calc(100vw / 3 - 60px - 30px);
  position: relative;
  padding-left: 15px;
  padding-right: 5px;
  color: #fff;
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.2;
}
.navbar div.sub2 > .item > .menu:before {
  display: block;
  content: "› ";
  position: absolute;
  left: 0;
  top: 0;
}
.navbar div.sub1 > .item:hover div.sub2 {
  max-width: 100%;
  transition: max-width 500ms ease;
}
.navbar div.sub1 > .item:hover div.sub2:empty {
  max-width: 0;
}
@media only screen and (min-width: 1280px) {
  .navbar div.sub2 {
    padding-top: 70px;
  }
  .navbar div.sub2 > .item {
    padding-left: 50px;
  }
  .navbar div.sub2 > .item > .menu {
    width: calc(100vw / 3 - 60px - 50px);
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.20833333;
  }
}
@media only screen and (min-width: 1920px) {
  .navbar div.sub2 {
    padding-top: 115px;
  }
  .navbar div.sub2 > .item {
    padding-left: 70px;
  }
  .navbar div.sub2 > .item > .menu {
    width: calc(100vw / 3 - 60px - 70px);
    font-size: 40px;
    font-size: 4rem;
    line-height: 1.2;
  }
}
.cb-home {
  width: 270px;
  height: 99px;
  margin-top: 60px;
  margin-bottom: 104px;
}
.wrapper {
  box-sizing: border-box;
  padding-left: 60px;
}
.logoblock {
  width: 320px;
}
.moodcontent {
  left: 17.08984375%;
  top: 150px;
  width: 49.31640625%;
}
.cb-page-layout2 .moodcontent {
  width: calc(100% - 320px);
  padding-left: 170px;
  padding-right: 80px;
}
.downlink,
.cb-goto-page-next {
  bottom: 40px;
}
.section--footer .logo {
  width: 260px;
  height: 96px;
}
.section--footer .cols.cols--meta {
  justify-content: flex-end;
}
.section--footer .cols.cols--meta .col {
  width: 66.66666667%;
}
.section--footer .cols.cols--meta .col .col-inner {
  box-sizing: border-box;
  width: 50%;
  padding: 30px;
  padding-left: 0;
}
.section--footer .cols.cols--meta .col.col--logo {
  width: 33.33333333%;
}
.section--footer .cols.cols--meta .col.col--logo .col-inner {
  float: right;
  width: 100%;
  padding-left: 30px;
}
.section--footer .cols.cols--meta .col.col--logo .col-inner .logo {
  float: right;
}
.section--footer .cols.cols--logos .col .logos .logo-container {
  width: 33.33333333%;
  max-width: 300px;
}
.wrapper {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.22222222;
}
@media only screen and (max-width: 1279px) {
  .cb-page-layout3 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.1875;
  }
}
h1,
h2 {
  padding-top: 20px;
}
h1 {
  font-size: 70px;
  font-size: 7rem;
}
.cb-page-layout2 h1 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.2;
}
.cb-page-layout3 h1,
.cb-page-layout4 h1,
.cb-page-layout5 h1,
.cb-page-layout6 h1,
h2 {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.2;
}
.cb-page-layout1 .base h2 {
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.2;
}
.loud {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.21428571;
}
.part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.part.link + .part.link {
  margin-top: -15px;
}
.cb-page-layout1 .area.main {
  width: 100%;
}
.cb-page-layout1 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .area.main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout1 .area.main .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .area.main > .slim {
  width: 33.33333333%;
}
.cb-page-layout1 .area.main > .slim .foot,
.cb-page-layout1 .area.main > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout1 .area.main > .slim .part {
  width: 100%;
}
.cb-page-layout1 .area.main > .slim .tiny {
  width: 100%;
}
.cb-page-layout1 .area.base {
  width: 100%;
}
.cb-page-layout1 .area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .area.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout1 .area.base .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout1 .area.base > .slim {
  width: 33.33333333%;
}
.cb-page-layout1 .area.base > .slim .foot,
.cb-page-layout1 .area.base > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout1 .area.base > .slim .part {
  width: 100%;
}
.cb-page-layout1 .area.base > .slim .tiny {
  width: 100%;
}
.cb-page-layout1 .area.main {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.cb-page-layout1 .area.main .unit {
  min-height: 100vh;
}
.cb-page-layout1 .area.main .unit.wide {
  width: 66.66666667%;
}
.cb-page-layout1 .area.main .unit.slim {
  width: 33.33333333%;
}
.cb-page-layout1 .area.main .unit.pure {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 12.25490196%;
}
.cb-page-layout1 .area.main .unit.flat .body {
  height: 100%;
}
.cb-page-layout1 .area.side .unit.pure {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px 10.7421875vw;
}
.cb-page-layout1 .area.side .unit.pure .part {
  margin: 0;
}
.cb-page-layout1 .area.base .unit {
  height: 50vh;
  min-height: 384px;
}
.cb-page-layout1 .area.base .unit.wide {
  width: 66.66666667%;
}
.cb-page-layout1 .area.base .unit.slim {
  width: 33.33333333%;
}
.cb-page-layout1 .area.base .unit.pure {
  padding: 30px;
}
.cb-page-layout3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.cb-page-layout3.cb-page-color-c2 .container--main {
  order: 2;
}
.cb-page-layout3.cb-page-color-c2 .container--side {
  order: 3;
}
.cb-page-layout3.cb-page-color-c2 .container--base {
  order: 1;
}
.cb-page-layout3 .cb-page-title {
  padding: 20px 5.88235294% 0;
}
.cb-page-layout3 .container {
  width: 33.33333333%;
}
.cb-page-layout3 .area.main,
.cb-page-layout3 .area.side {
  width: 100%;
}
.cb-page-layout3 .area.main > .unit,
.cb-page-layout3 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout3 .area.main .foot,
.cb-page-layout3 .area.side .foot {
  margin-right: 5.88235294%;
  margin-left: 5.88235294%;
}
.cb-page-layout3 .area.main .part,
.cb-page-layout3 .area.side .part {
  margin-right: 5.88235294%;
  margin-left: 5.88235294%;
  width: 88.23529412%;
}
.cb-page-layout3 .area.main .tiny,
.cb-page-layout3 .area.side .tiny {
  width: 38.23529412%;
}
.cb-page-layout3 .area.main > .slim,
.cb-page-layout3 .area.side > .slim {
  width: 50%;
}
.cb-page-layout3 .area.main > .slim .foot,
.cb-page-layout3 .area.side > .slim .foot,
.cb-page-layout3 .area.main > .slim .part,
.cb-page-layout3 .area.side > .slim .part {
  margin-right: 11.76470588%;
  margin-left: 11.76470588%;
}
.cb-page-layout3 .area.main > .slim .part,
.cb-page-layout3 .area.side > .slim .part {
  width: 76.47058824%;
}
.cb-page-layout3 .area.main > .slim .tiny,
.cb-page-layout3 .area.side > .slim .tiny {
  width: 76.47058824%;
}
.cb-page-layout3 .area.main,
.cb-page-layout3 .area.side,
.cb-page-layout3 .area.base {
  height: 100%;
}
.cb-page-layout3 .area.main .unit.pure,
.cb-page-layout3 .area.side .unit.pure,
.cb-page-layout3 .area.base .unit.pure {
  padding: 5px 0 20px;
}
.cb-page-layout3 .area.main .unit.flat,
.cb-page-layout3 .area.side .unit.flat,
.cb-page-layout3 .area.base .unit.flat {
  height: 100vh;
}
.cb-page-layout3 .area.main .unit.flat .body,
.cb-page-layout3 .area.side .unit.flat .body,
.cb-page-layout3 .area.base .unit.flat .body {
  height: 100%;
}
.cb-page-layout3 .area.main .unit.pure {
  padding-top: 15px;
}
.cb-page-layout4 .cb-page-title {
  padding: 20px 3.92156863% 0;
}
.cb-page-layout4 .area.main {
  width: 100%;
}
.cb-page-layout4 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout4 .area.main .foot {
  margin-right: 3.92156863%;
  margin-left: 3.92156863%;
}
.cb-page-layout4 .area.main .part {
  margin-right: 3.92156863%;
  margin-left: 3.92156863%;
  width: 92.15686275%;
}
.cb-page-layout4 .area.main .tiny {
  width: 25.49019608%;
}
.cb-page-layout4 .area.main > .slim {
  width: 33.33333333%;
}
.cb-page-layout4 .area.main > .slim .foot,
.cb-page-layout4 .area.main > .slim .part {
  margin-right: 11.76470588%;
  margin-left: 11.76470588%;
}
.cb-page-layout4 .area.main > .slim .part {
  width: 76.47058824%;
}
.cb-page-layout4 .area.main > .slim .tiny {
  width: 26.47058824%;
}
.cb-page-layout5 .cb-page-title {
  padding: 20px 1.96078431% 0;
}
.cb-page-layout5 .area.main {
  width: 100%;
}
.cb-page-layout5 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout5 .area.main .foot {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
}
.cb-page-layout5 .area.main .part {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
  width: 96.07843137%;
}
.cb-page-layout5 .area.main .tiny {
  width: 46.07843137%;
}
.cb-page-layout5 .area.main > .slim {
  width: 50%;
}
.cb-page-layout5 .area.main > .slim .foot,
.cb-page-layout5 .area.main > .slim .part {
  margin-right: 3.92156863%;
  margin-left: 3.92156863%;
}
.cb-page-layout5 .area.main > .slim .part {
  width: 92.15686275%;
}
.cb-page-layout5 .area.main > .slim .tiny {
  width: 42.15686275%;
}
.cb-page-layout5 .area.main .unit.seam:before {
  left: 1.96078431%;
}
.cb-page-layout5 .area.main .unit.seam.slim:before {
  left: 3.92156863%;
}
.cb-page-layout6 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.cb-page-layout6.cb-page-color-c2 .container--main {
  order: 2;
}
.cb-page-layout6.cb-page-color-c2 .container--base {
  order: 1;
}
.cb-page-layout6 .cb-page-title {
  padding: 0;
}
.cb-page-layout6 .container {
  width: 50%;
}
.cb-page-layout6 .container--main {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 30px 7.84313725%;
}
.cb-page-layout6 .area.main {
  width: 100%;
}
.cb-page-layout6 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout6 .area.main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-page-layout6 .area.main .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout6 .area.main > .slim .part {
  width: 100%;
}
.cb-page-layout6 .area.main .unit.flat,
.cb-page-layout6 .area.base .unit.flat {
  height: 100vh;
}
.cb-page-layout6 .area.main .unit.flat .body,
.cb-page-layout6 .area.base .unit.flat .body {
  height: 100%;
}
div.link .open {
  line-height: 18px;
  padding-right: 18px;
  background-size: 11px 18px;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp,
.recommend .disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */